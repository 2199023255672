import { ChainId, CurrencyAmount, JSBI, Pair, Token, TokenAmount } from '@uniswap/sdk'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  APR_API_LIST,
  CERE,
  NULL_TOKEN,
  NULL_TOKEN_AMOUNT,
  PoolActiveStatus,
  PoolStatus,
  StakingMode,
  stakingMode,
  UNIV2,
  ZERO_ADDRESS
} from '../../constants'
import { STAKING_REWARDS_INTERFACE } from '../../constants/abis/staking-rewards'
import ERC20_INTERFACE from '../../constants/abis/erc20'
import { useActiveWeb3React } from '../../hooks'
import { NEVER_RELOAD, useMultipleContractSingleData } from '../multicall/hooks'
import { tryParseAmount } from '../swap/hooks'
import useCurrentBlockTimestamp from 'hooks/useCurrentBlockTimestamp'
import { get } from '../../utils/request'
import { NETWORK_CHAIN_ID } from '../../connectors'
import Web3 from 'web3'
import STAKING_REWARDS_ABI from '../../constants/abis/StakingRewards.json'
import { AbiItem } from 'web3-utils'

export const STAKING_GENESIS = 1621916684

export const REWARDS_DURATION_DAYS = 60

const networkChainId: ChainId = NETWORK_CHAIN_ID as ChainId
const networkUrl = process.env.REACT_APP_NETWORK_URL as string
const stakingRewardsAddress = process.env.REACT_APP_STAKING_REWARDS_ADDRESS

const web3 = new Web3(new Web3.providers.HttpProvider(networkUrl))
const stakingRewardContractInstance = new web3.eth.Contract(STAKING_REWARDS_ABI as AbiItem[], stakingRewardsAddress)

// TODO add staking rewards addresses here
export const STAKING_REWARDS_INFO: {
  [chainId in ChainId]?: {
    tokens: [Token, Token]
    stakingRewardAddress: string
  }[]
} = {
  [NETWORK_CHAIN_ID]: [
    {
      tokens:
        stakingMode === StakingMode.Default
          ? [UNIV2[networkChainId], CERE[networkChainId]]
          : [CERE[networkChainId], CERE[networkChainId]],
      stakingRewardAddress: stakingRewardsAddress
    }
  ]
}

export interface StakingInfo {
  APR: any
  // the address of the reward contract
  stakingRewardAddress: string
  // the tokens involved in this pair
  tokens: [Token, Token]
  // the amount of token currently staked, or undefined if no account
  stakedAmount: TokenAmount
  // the amount of reward token earned by the active account, or undefined if no account
  earnedAmount: TokenAmount
  // the total amount of token staked in the contract
  totalStakedAmount: TokenAmount
  // the amount of token distributed per second to all LPs, constant
  totalRewardRate: TokenAmount
  // the current amount of token distributed to the active account per second.
  // equivalent to percent of total supply * reward rate
  rewardRate: TokenAmount
  // when the period ends
  periodFinish: Date | undefined
  // if pool is active
  active: boolean
  // account lp balance
  lpBalanceAmount: TokenAmount
  // calculates a hypothetical amount of token distributed to the active account per second.
  getHypotheticalRewardRate: (
    stakedAmount: TokenAmount,
    totalStakedAmount: TokenAmount,
    totalRewardRate: TokenAmount
  ) => TokenAmount
  // total stake amount
  totalStake: any
  // staking pool cap
  stakingPoolCap: any
}

function createNullStakingInfo(totalSupply: string | undefined): StakingInfo {
  return {
    APR: -1,
    active: true,
    earnedAmount: NULL_TOKEN_AMOUNT[networkChainId],
    getHypotheticalRewardRate(
      stakedAmount: TokenAmount,
      totalStakedAmount: TokenAmount,
      totalRewardRate: TokenAmount
    ): TokenAmount {
      return NULL_TOKEN_AMOUNT[networkChainId]
    },
    lpBalanceAmount: NULL_TOKEN_AMOUNT[networkChainId],
    periodFinish: undefined,
    rewardRate: NULL_TOKEN_AMOUNT[networkChainId],
    stakedAmount: NULL_TOKEN_AMOUNT[networkChainId],
    stakingPoolCap: -1,
    stakingRewardAddress: ZERO_ADDRESS,
    tokens: [NULL_TOKEN[networkChainId], NULL_TOKEN[networkChainId]],
    totalRewardRate: NULL_TOKEN_AMOUNT[networkChainId],
    totalStake: [totalSupply],
    totalStakedAmount: NULL_TOKEN_AMOUNT[networkChainId]
  }
}

export function isNullStakingInfo(si: StakingInfo): boolean {
  return si.APR === -1
}

// gets the staking info from the network for the active chain id
export function useStakingInfo(pairToFilterBy?: Pair | null): StakingInfo[] {
  const { chainId, account } = useActiveWeb3React()

  // detect if staking is ended
  const currentBlockTimestamp = useCurrentBlockTimestamp()

  const info = useMemo(
    () =>
      chainId
        ? STAKING_REWARDS_INFO[chainId]?.filter(stakingRewardInfo =>
            pairToFilterBy === undefined
              ? true
              : pairToFilterBy === null
              ? false
              : pairToFilterBy.involvesToken(stakingRewardInfo.tokens[0]) &&
                pairToFilterBy.involvesToken(stakingRewardInfo.tokens[1])
          ) ?? []
        : [],
    [chainId, pairToFilterBy]
  )

  const uni = chainId ? UNIV2[chainId] : undefined
  const cere = chainId ? CERE[chainId] : undefined
  const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])

  const accountArg = useMemo(() => [account ?? undefined], [account])

  // get all the info from the staking rewards contracts
  const balances = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'balanceOf', accountArg)
  const earnedAmounts = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'earned', accountArg)
  const totalSupplies = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'totalSupply')
  const stakingTokens = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'stakingToken')
  const APRs = useAPR(rewardsAddresses)
  const stakingTokenAddrs = stakingTokens.map(({ result }) => result?.[0])
  const lpBalances = useMultipleContractSingleData(stakingTokenAddrs, ERC20_INTERFACE, 'balanceOf', accountArg)
  const stakingPoolCap = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'stakingPoolCap')

  // tokens per second, constants
  const rewardRates = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    'rewardRate',
    undefined,
    NEVER_RELOAD
  )
  const periodFinishes = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    'periodFinish',
    undefined,
    NEVER_RELOAD
  )

  const totalSupply = useFetchTotalSupply()

  return useMemo(() => {
    if (!chainId || !uni) return []

    if (!account) {
      return [createNullStakingInfo(totalSupply)]
    }

    return rewardsAddresses.reduce<StakingInfo[]>((memo, rewardsAddress, index) => {
      // these two are dependent on account
      const balanceState = balances[index]
      const earnedAmountState = earnedAmounts[index]

      // these get fetched regardless of account
      const totalSupplyState = totalSupplies[index]
      const rewardRateState = rewardRates[index]
      const periodFinishState = periodFinishes[index]
      const stakingToken = stakingTokens[index]
      const lpBalance = lpBalances[index]
      if (
        // these may be undefined if not logged in
        !balanceState?.loading &&
        !earnedAmountState?.loading &&
        // always need these
        totalSupplyState &&
        !totalSupplyState.loading &&
        stakingToken &&
        !lpBalance.loading &&
        lpBalance &&
        !stakingToken.loading &&
        rewardRateState &&
        !rewardRateState.loading &&
        periodFinishState &&
        !periodFinishState.loading
      ) {
        if (
          balanceState?.error ||
          earnedAmountState?.error ||
          totalSupplyState.error ||
          stakingToken.error ||
          lpBalance.error ||
          rewardRateState.error ||
          periodFinishState.error
        ) {
          console.error('Failed to load staking rewards info')
          return memo
        }

        // get the LP token
        const decimals = stakingMode === StakingMode.Default ? 18 : 10
        const symbol = stakingMode === StakingMode.Default ? 'UNI-V2' : 'CERE'
        const name = stakingMode === StakingMode.Default ? 'Uniswap v2' : 'CERE Network'
        const lp_token = new Token(chainId, stakingToken.result?.[0], decimals, symbol, name)

        // check for account, if no account set to 0
        const stakedAmount = new TokenAmount(lp_token, JSBI.BigInt(balanceState?.result?.[0] ?? 0))
        const totalStakedAmount = new TokenAmount(lp_token, JSBI.BigInt(totalSupplyState.result?.[0]))
        const totalRewardRate = new TokenAmount(uni, JSBI.BigInt(rewardRateState.result?.[0]))
        const lpBalanceAmount = new TokenAmount(lp_token, JSBI.BigInt(lpBalance?.result?.[0] ?? 0))

        const getHypotheticalRewardRate = (
          stakedAmount: TokenAmount,
          totalStakedAmount: TokenAmount,
          totalRewardRate: TokenAmount
        ): TokenAmount => {
          return new TokenAmount(
            uni,
            JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
              ? JSBI.divide(JSBI.multiply(totalRewardRate.raw, stakedAmount.raw), totalStakedAmount.raw)
              : JSBI.BigInt(0)
          )
        }

        const individualRewardRate = getHypotheticalRewardRate(stakedAmount, totalStakedAmount, totalRewardRate)

        const periodFinishSeconds = periodFinishState.result?.[0]?.toNumber()
        const periodFinishMs = periodFinishSeconds * 1000

        // compare period end timestamp vs current block timestamp (in seconds)
        const active =
          periodFinishSeconds && currentBlockTimestamp ? periodFinishSeconds > currentBlockTimestamp.toNumber() : true

        memo.push({
          APR: APRs[rewardsAddress],
          stakingRewardAddress: rewardsAddress,
          tokens: info[index].tokens,
          periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
          earnedAmount: new TokenAmount(cere as Token, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)),
          rewardRate: individualRewardRate,
          totalRewardRate: totalRewardRate,
          stakedAmount: stakedAmount,
          totalStakedAmount: totalStakedAmount,
          getHypotheticalRewardRate,
          active,
          lpBalanceAmount,
          totalStake: totalSupplies[0].result,
          stakingPoolCap: stakingPoolCap[0].result
        })
      }
      return memo
    }, [])
  }, [
    balances,
    chainId,
    currentBlockTimestamp,
    earnedAmounts,
    info,
    periodFinishes,
    rewardRates,
    rewardsAddresses,
    totalSupplies,
    uni,
    totalSupply
  ])
}

export function useTotalUniEarned(): TokenAmount | undefined {
  const { chainId } = useActiveWeb3React()
  const uni = chainId ? UNIV2[chainId] : undefined
  const stakingInfos = useStakingInfo()

  return useMemo(() => {
    if (!uni) return undefined
    return (
      stakingInfos?.reduce(
        (accumulator, stakingInfo) => accumulator.add(stakingInfo.earnedAmount),
        new TokenAmount(uni, '0')
      ) ?? new TokenAmount(uni, '0')
    )
  }, [stakingInfos, uni])
}

// based on typed value
export function useDerivedStakeInfo(
  typedValue: string,
  stakingToken: Token,
  userLiquidityUnstaked: TokenAmount | undefined
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingToken)

  const parsedAmount =
    parsedInput && userLiquidityUnstaked && JSBI.lessThanOrEqual(parsedInput.raw, userLiquidityUnstaked.raw)
      ? parsedInput
      : undefined

  let error: string | undefined
  if (!account) {
    error = t('conect_wallet')
  }
  if (!parsedAmount) {
    error = error ?? t('enter_an_amount')
  }

  return {
    parsedAmount,
    error
  }
}

// based on typed value
export function useDerivedUnstakeInfo(
  typedValue: string,
  stakingAmount: TokenAmount
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingAmount.token)

  const parsedAmount = parsedInput && JSBI.lessThanOrEqual(parsedInput.raw, stakingAmount.raw) ? parsedInput : undefined

  let error: string | undefined
  if (!account) {
    error = t('conect_wallet')
  }
  if (!parsedAmount) {
    error = error ?? t('enter_an_amount')
  }

  return {
    parsedAmount,
    error
  }
}

export function useAPR(rewardsAddresses: any): any {
  const [APR, setAPR] = useState<any>({})
  const { chainId } = useActiveWeb3React()
  const api_url = chainId ? APR_API_LIST[chainId] : APR_API_LIST[4]
  const fetchAPR = useCallback(
    async address => {
      if (!address) {
        return
      }
      get(`${api_url}/apr?addr=${address}`).then((response: any) => {
        if (response) {
          setAPR((oldAPR: any) => {
            return { ...oldAPR, [address]: response.apr }
          })
        }
      })
    },
    [rewardsAddresses, APR]
  )

  useEffect(() => {
    for (let i = 0; i < rewardsAddresses.length; i++) {
      fetchAPR(rewardsAddresses[i])
    }
  }, [rewardsAddresses])
  return { ...APR }
}

export const calculateUsdPrice = (amount: number, totalSupply: number, usdReserve: number) => {
  return (amount / totalSupply) * usdReserve * 2
}

export function usePrice(amount: number): any {
  const [price, setPrice] = useState<number>(0)

  const calculatePriceWithUniswap = useCallback(() => {
    const gqlQuery = {
      query: `query($pairId: String!){
          pair(id: $pairId){
              totalSupply
              reserve0
              reserve1
              token0Price
              token1Price
              token0 {
                id
                symbol
                name
                decimals
              }
              token1 {
                id
                symbol
                name
                decimals
              }
            }
          }`,
      variables: {
        pairId: process.env.REACT_APP_PAIR_CONTRACT_ADDRESS
      }
    }

    fetch('https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(gqlQuery)
    })
      .then(res => res.json())
      .then(result => {
        const totalSupply = Number(result.data.pair.totalSupply)
        const usdtSupply = Number(result.data.pair.reserve1)

        setPrice(calculateUsdPrice(amount, totalSupply, usdtSupply))
      })
      .catch(error => {
        console.error('fetchCurrencyPairUniswapData function ERROR:', error)
        setPrice(0)
      })
  }, [amount])

  const calculatePriceWithCoinGecko = useCallback(() => {
    fetch('https://api.coingecko.com/api/v3/simple/price?ids=cere-network&vs_currencies=usd', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(result => {
        setPrice(result['cere-network'].usd * amount)
      })
      .catch(error => {
        console.error('fetch coingeck function ERROR:', error)
        setPrice(0)
      })
  }, [amount])

  if (stakingMode === StakingMode.Default) {
    calculatePriceWithUniswap()
  } else {
    calculatePriceWithCoinGecko()
  }

  return price
}

export function useFetchTotalSupply() {
  const [totalSupply, setTotalSupply] = useState('0')
  async function fetchTotalSupply() {
    try {
      const ts = await stakingRewardContractInstance.methods.totalSupply().call()
      setTotalSupply(ts)
    } catch (error) {
      console.error(`Error during total supply fetch ${error}`)
    }
  }
  fetchTotalSupply()
  return totalSupply
}

export function useFetchStakePoolCap() {
  const [stakePoolCap, setStakePoolCap] = useState('0')
  async function fetchStakePoolCap() {
    try {
      const ts = await stakingRewardContractInstance.methods.stakingPoolCap().call()
      setStakePoolCap(ts)
    } catch (error) {
      console.error(`Error during stake pool cap fetch ${error}`)
    }
  }
  fetchStakePoolCap()
  return stakePoolCap
}

export function usePoolStatus(totalStaked: any, stakePoolCap: any) {
  const [poolStatus, setPoolStatus] = useState(PoolStatus.NotCalculated)
  const [poolColor, setPoolColor] = useState('white')
  const [poolHelpText, setPoolHelpText] = useState('')

  useMemo(() => {
    const per = (totalStaked / stakePoolCap) * 100
    if (Math.round(per) < 95 && Math.round(per) > 0) {
      setPoolStatus(PoolStatus.Open)
      setPoolColor('green')
      setPoolHelpText('The pool is open')
    } else if (Math.round(per) >= 95 && Math.round(per) < 100) {
      setPoolStatus(PoolStatus.AlmostFull)
      setPoolColor('yellow')
      setPoolHelpText('The pool is almost full')
    } else if (Math.round(per) == 100) {
      setPoolStatus(PoolStatus.Full)
      setPoolColor('red')
      setPoolHelpText('The pool is full')
    } else if (Math.round(per) > 100 || Math.round(per) < 0) {
      setPoolStatus(PoolStatus.NotCalculated)
    }
  }, [totalStaked, stakePoolCap])

  return { poolColor, poolHelpText, poolStatus }
}

export function useConvertToIntlCurrency(amount: number) {
  if (amount == 0) {
    return 0
  }
  // Nine Zeroes for Billions
  return Math.abs(amount) >= 1.0e9
    ? (Math.abs(amount) / 1.0e9).toFixed(2) + 'B'
    : // Six Zeroes for Millions
    Math.abs(amount) >= 1.0e6
    ? (Math.abs(amount) / 1.0e6).toFixed(2) + 'M'
    : // Three Zeroes for Thousands
    Math.abs(amount) >= 1.0e3
    ? (Math.abs(amount) / 1.0e3).toFixed(2) + 'K'
    : Math.abs(amount).toFixed(2)
}

export function useFetchStakingPeriodStatus() {
  const [active, setActive] = useState(PoolActiveStatus.Unknown)
  async function fetchStakePeriodStatus() {
    try {
      // detect if staking is ended
      const { timestamp: currentBlockTimestamp } = await web3.eth.getBlock('latest')
      const periodFinish = await stakingRewardContractInstance.methods.periodFinish().call()
      // compare period end timestamp vs current block timestamp (in seconds)
      const active = periodFinish && currentBlockTimestamp ? periodFinish > currentBlockTimestamp : true
      setActive(active ? PoolActiveStatus.Active : PoolActiveStatus.Inactive)
    } catch (error) {
      console.error(`Error during stake pool cap fetch ${error}`)
      setActive(PoolActiveStatus.Unknown)
    }
  }
  fetchStakePeriodStatus()
  return active
}

export function useFetchStakingFinishPeriod() {
  const [finishPeriod, setFinishPeriod] = useState(0)
  async function fetchStakingFinishPeriod() {
    try {
      const periodFinish = await stakingRewardContractInstance.methods.periodFinish().call()
      const periodFinishMs = periodFinish * 1000
      console.log(periodFinishMs)
      setFinishPeriod(periodFinishMs)
    } catch (error) {
      console.error(`Error while fetching staking finish period ${error}`)
      setFinishPeriod(0)
    }
  }
  fetchStakingFinishPeriod()
  return finishPeriod
}
