import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import useIsArgentWallet from '../../hooks/useIsArgentWallet'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { ButtonConfirmed, ButtonError } from '../Button'
import ProgressCircles from '../ProgressSteps'
import CurrencyInputPanel from '../CurrencyInputPanel'
import { TokenAmount, Pair } from '@uniswap/sdk'
import { useActiveWeb3React } from '../../hooks'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { usePairContract, useStakingContract } from '../../hooks/useContract'
import { useApproveCallback, ApprovalState } from '../../hooks/useApproveCallback'
import { splitSignature } from 'ethers/lib/utils'
import { StakingInfo, useDerivedStakeInfo } from '../../state/stake/hooks'
import { wrappedCurrencyAmount } from '../../utils/wrappedCurrency'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { LoadingView, SubmittedView } from '../ModalViews'
import { utils } from 'ethers'
import Decimal from 'decimal.js'
import { epsilon, StakingMode, stakingMode, zeroDecimal } from '../../constants/index'

const HypotheticalRewardRate = styled.div<{ dim: boolean }>`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;

  opacity: ${({ dim }) => (dim ? 0.5 : 1)};
`

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

const StakingError = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
  color: red;
`

interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfo: StakingInfo
  userLiquidityUnstaked: TokenAmount | undefined
  apr: string
}

export default function StakingModal({
  apr,
  isOpen,
  onDismiss,
  stakingInfo,
  userLiquidityUnstaked
}: StakingModalProps) {
  const { account, chainId, library } = useActiveWeb3React()
  const { t } = useTranslation()

  // track and parse user input
  const [typedValue, setTypedValue] = useState('')
  const [stakingLimit, setStakingLimit] = useState(false)
  const [stakeError, setStakeError] = useState('')
  const { parsedAmount, error } = useDerivedStakeInfo(
    typedValue,
    stakingInfo.stakedAmount?.token,
    userLiquidityUnstaked
  )
  const parsedAmountWrapped = wrappedCurrencyAmount(parsedAmount, chainId)

  let hypotheticalRewardRate: TokenAmount = new TokenAmount(stakingInfo.rewardRate?.token, '0')
  if (parsedAmountWrapped?.greaterThan('0')) {
    hypotheticalRewardRate = stakingInfo.getHypotheticalRewardRate(
      stakingInfo.stakedAmount.add(parsedAmountWrapped),
      stakingInfo.totalStakedAmount.add(parsedAmountWrapped),
      stakingInfo.totalRewardRate
    )
  }

  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder()
  const [attempting, setAttempting] = useState<boolean>(false)
  const [hash, setHash] = useState<string | undefined>()
  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }, [onDismiss])

  // pair contract for this token to be staked
  const currencyPair =
    stakingMode === StakingMode.Default
      ? new Pair(new TokenAmount(stakingInfo.tokens[0], '0'), new TokenAmount(stakingInfo.tokens[1], '0'))
      : null

  // approval data for stake
  const deadline = useTransactionDeadline()
  const [signatureData, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(null)
  const [approval, approveCallback] = useApproveCallback(parsedAmount, stakingInfo.stakingRewardAddress)

  const isArgentWallet = useIsArgentWallet()
  const stakingContract = useStakingContract(stakingInfo.stakingRewardAddress)

  const [disabled, setDisabled] = useState<boolean>(true)
  const [confirmed, setConfirmed] = useState<boolean>(false)

  async function onStake() {
    setAttempting(true)
    if (stakingContract && parsedAmount && deadline) {
      if (approval === ApprovalState.APPROVED) {
        await stakingContract
          .stake(`0x${parsedAmount.raw.toString(16)}`, { gasLimit: 350000 })
          .then((response: TransactionResponse) => {
            onDismiss()
            addTransaction(response, {
              summary: `Deposit tokens`
            })
            setHash(response.hash)
          })
          .catch((error: any) => {
            setAttempting(false)
            console.log(error)
          })
      } else {
        setAttempting(false)
        throw new Error('Attempting to stake without approval or a signature. Please contact support.')
      }
    }
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
    (typedValue: string) => {
      setSignatureData(null)
      setTypedValue(typedValue)
      validateInput(typedValue)
    },
    [stakingInfo]
  )

  // validate users staking with max staking availability
  const validateInput = (userInput: string) => {
    if (userInput === '') {
      userInput = '0'
    }
    const stakeAvailable = stakingInfo.stakingPoolCap - stakingInfo.totalStake
    const userInputBigInt = new Decimal(userInput)
    const decimals = 10 ** (stakingMode === StakingMode.Default ? 18 : 10)
    const maxStakedValueBigInt = new Decimal(stakeAvailable / decimals)
    const difference = maxStakedValueBigInt.minus(userInputBigInt)

    if (epsilon.greaterThan(difference)) {
      if (parseFloat(zeroDecimal.toString()) == parseFloat(difference.toString())) {
        setStakingLimit(false)
        setStakeError('')
        return
      }
      setStakingLimit(true)
      setStakeError(
        `Max Staking Pool limit reached. You can stake max. ${stakeAvailable /
          decimals} amount or wait and retry later.`
      )
      setDisabled(true)
      setConfirmed(false)
    } else {
      setStakingLimit(false)
      setDisabled(false)
      setConfirmed(true)
      setStakeError('')
    }
  }

  // used for max input button
  const maxAmountInput = maxAmountSpend(userLiquidityUnstaked)
  const atMaxAmount = Boolean(maxAmountInput && parsedAmount?.equalTo(maxAmountInput))
  const handleMax = useCallback(() => {
    maxAmountInput && onUserInput(maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <TYPE.mediumHeader>
              {t('stake')} {stakingInfo.tokens[0].symbol}
            </TYPE.mediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          <CurrencyInputPanel
            value={typedValue}
            onUserInput={onUserInput}
            onMax={handleMax}
            showMaxButton={!atMaxAmount}
            currency={stakingInfo.tokens[0]}
            pair={currencyPair}
            label={''}
            disableCurrencySelect={true}
            customBalanceText={`${t('available_to_deposit')}: `}
            id="stake-liquidity-token"
          />
          {stakingLimit ? <StakingError>{stakeError}</StakingError> : null}
          <HypotheticalRewardRate dim={!hypotheticalRewardRate.greaterThan('0')}>
            <div>
              <TYPE.black fontWeight={600}>{t('apr')}</TYPE.black>
            </div>

            <TYPE.black>{apr}</TYPE.black>
          </HypotheticalRewardRate>

          <RowBetween>
            <ButtonConfirmed
              mr="0.5rem"
              onClick={approveCallback}
              confirmed={(approval === ApprovalState.APPROVED || signatureData !== null) && confirmed}
              disabled={(approval !== ApprovalState.NOT_APPROVED || signatureData !== null) && disabled}
            >
              {t('approve')}
            </ButtonConfirmed>
            <ButtonError
              disabled={!!error || (signatureData === null && approval !== ApprovalState.APPROVED) || disabled}
              error={!!error && !!parsedAmount}
              onClick={onStake}
            >
              {error ?? 'Deposit'}
            </ButtonError>
          </RowBetween>
          <ProgressCircles steps={[approval === ApprovalState.APPROVED || signatureData !== null]} disabled={true} />
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>{t('depositing_liquidity')}</TYPE.largeHeader>
            <TYPE.body fontSize={20}>
              {parsedAmount?.toSignificant(4)} {stakingInfo?.tokens[0]?.symbol}
            </TYPE.body>
          </AutoColumn>
        </LoadingView>
      )}
      {attempting && hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>{t('transaction_submitted')}</TYPE.largeHeader>
            <TYPE.body fontSize={20}>
              {t('deposited')} {parsedAmount?.toSignificant(4)} {stakingInfo?.earnedAmount?.currency?.symbol}
            </TYPE.body>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
