import { ChainId, JSBI, Percent, Token, TokenAmount, WETH as UWETH } from '@uniswap/sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'
import Decimal from 'decimal.js'

import { fortmatic, injected, portis, walletconnect, walletlink } from '../connectors'

//export const ROUTER_ADDRESS = '0x38A436197D523efAd879BdBbaE6B918021bC9D74'
export const ROUTER_ADDRESS = '0x13CE3345fC76f9F4f64c7df7cCaf55AffA4C1F17'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export { PRELOADED_PROPOSALS } from './proposals'

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

type ApiURLList = {
  readonly [chainId in ChainId]: string | undefined
}

export const WETH = {
  ...UWETH,
  80001: new Token(80001, '0xd0A1E359811322d97991E03f863a0C30C2cF029C', 18, 'WETH', 'Wrapped ETH'),
  137: new Token(137, '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619', 18, 'WETH', 'Wrapped ETH')
}

export const WMATIC: { [chainId in ChainId]: Token } = {
  [ChainId.POLYGON]: new Token(137, '0x90336b0C3f80F1eD297D5A9aBD896070fCdAE54b', 18, 'WMATIC', 'Wrapped MATIC'),
  [ChainId.MUMBAI]: new Token(80001, '0x90336b0C3f80F1eD297D5A9aBD896070fCdAE54b', 18, 'WMATIC', 'Wrapped MATIC'),
  [ChainId.MAINNET]: new Token(80001, '0x90336b0C3f80F1eD297D5A9aBD896070fCdAE54b', 18, 'WMATIC', 'Wrapped MATIC'),
  [ChainId.RINKEBY]: new Token(80001, '0x90336b0C3f80F1eD297D5A9aBD896070fCdAE54b', 18, 'WMATIC', 'Wrapped MATIC'),
  [ChainId.ROPSTEN]: new Token(80001, '0x90336b0C3f80F1eD297D5A9aBD896070fCdAE54b', 18, 'WMATIC', 'Wrapped MATIC'),
  [ChainId.GÖRLI]: new Token(80001, '0x90336b0C3f80F1eD297D5A9aBD896070fCdAE54b', 18, 'WMATIC', 'Wrapped MATIC'),
  [ChainId.KOVAN]: new Token(80001, '0x90336b0C3f80F1eD297D5A9aBD896070fCdAE54b', 18, 'WMATIC', 'Wrapped MATIC')
}

export const USDR = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x6539aa1C0652aF2fC10a5c0fEF4f3D8fA44Ef6Dd', 6, 'USDT', 'USDT'),
  [ChainId.ROPSTEN]: new Token(ChainId.ROPSTEN, '0x6539aa1C0652aF2fC10a5c0fEF4f3D8fA44Ef6Dd', 6, 'USDT', 'USDT'),
  [ChainId.RINKEBY]: new Token(ChainId.RINKEBY, '0x6539aa1C0652aF2fC10a5c0fEF4f3D8fA44Ef6Dd', 6, 'USDT', 'USDT'),
  [ChainId.GÖRLI]: new Token(ChainId.GÖRLI, '0x6539aa1C0652aF2fC10a5c0fEF4f3D8fA44Ef6Dd', 6, 'USDT', 'USDT'),
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, '0x6539aa1C0652aF2fC10a5c0fEF4f3D8fA44Ef6Dd', 6, 'USDT', 'USDT'),
  [ChainId.POLYGON]: new Token(ChainId.POLYGON, '0x6539aa1C0652aF2fC10a5c0fEF4f3D8fA44Ef6Dd', 6, 'USDT', 'USDT'),
  [ChainId.MUMBAI]: new Token(ChainId.MUMBAI, '0x6539aa1C0652aF2fC10a5c0fEF4f3D8fA44Ef6Dd', 6, 'USDT', 'USDT')
}

export const AMPL = new Token(ChainId.MAINNET, '0xD46bA6D942050d489DBd938a2C909A5d5039A161', 9, 'AMPL', 'Ampleforth')
export const DAI = new Token(ChainId.MAINNET, '0x6B175474E89094C44Da98b954EedeAC495271d0F', 18, 'DAI', 'Dai Stablecoin')
export const USDC = new Token(ChainId.MAINNET, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 6, 'USDC', 'USD//C')
export const USDT = new Token(ChainId.MAINNET, '0xdAC17F958D2ee523a2206206994597C13D831ec7', 6, 'USDT', 'Tether USD')
export const WBTC = new Token(ChainId.MAINNET, '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599', 8, 'WBTC', 'Wrapped BTC')
export const FEI = new Token(ChainId.MAINNET, '0x956F47F50A910163D8BF957Cf5846D573E7f87CA', 18, 'FEI', 'Fei USD')
export const TRIBE = new Token(ChainId.MAINNET, '0xc7283b66Eb1EB5FB86327f08e1B5816b0720212B', 18, 'TRIBE', 'Tribe')
export const FRAX = new Token(ChainId.MAINNET, '0x853d955aCEf822Db058eb8505911ED77F175b99e', 18, 'FRAX', 'Frax')
export const FXS = new Token(ChainId.MAINNET, '0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0', 18, 'FXS', 'Frax Share')
export const renBTC = new Token(ChainId.MAINNET, '0xEB4C2781e4ebA804CE9a9803C67d0893436bB27D', 8, 'renBTC', 'renBTC')
// export const TUSDT = new Token(ChainId.RINKEBY, '0xB7A70A06bC6123445d52Fcd1D91553141F1d89F2', 18, "USDT", "Tether USD")

export const TEST = new Token(ChainId.RINKEBY, '0xE21A72A93A475cD835919FbDc0478f567B3Cd4ed', 18, 'TEST', 'TEST')

export const CERE: { [chainId in ChainId]: Token } = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x2da719db753dfa10a62e140f436e1d67f2ddb0d6', 10, 'CERE', 'CERE'),
  [ChainId.ROPSTEN]: new Token(ChainId.ROPSTEN, '0x2da719db753dfa10a62e140f436e1d67f2ddb0d6', 10, 'CERE', 'CERE'),
  [ChainId.RINKEBY]: new Token(ChainId.RINKEBY, '0x0b10e304088b2BA2B2acfD2f72573FAaD31a13A5', 10, 'CERE', 'CERE'),
  [ChainId.GÖRLI]: new Token(ChainId.GÖRLI, '0x0b10e304088b2BA2B2acfD2f72573FAaD31a13A5', 10, 'CERE', 'CERE'),
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, '0x2da719db753dfa10a62e140f436e1d67f2ddb0d6', 10, 'CERE', 'CERE'),
  [ChainId.POLYGON]: new Token(ChainId.POLYGON, '0x2da719db753dfa10a62e140f436e1d67f2ddb0d6', 10, 'CERE', 'CERE'),
  [ChainId.MUMBAI]: new Token(ChainId.MUMBAI, '0x2da719db753dfa10a62e140f436e1d67f2ddb0d6', 10, 'CERE', 'CERE')
}

export const TBTC: { [chainId in ChainId]: Token } = {
  137: new Token(137, '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6', 18, 'WBTC', 'Wraper Bitcoin'),
  [ChainId.MAINNET]: new Token(ChainId.RINKEBY, '0x6A2eDf35279FB398Abc8BE5CC8eD007d64726fc7', 18, 'BTC', 'Bitcoin'),
  [ChainId.RINKEBY]: new Token(ChainId.RINKEBY, '0x7039DF59Aed9b72157F812e012727d620546573f', 18, 'BTC', 'Bitcoin'),
  [ChainId.ROPSTEN]: new Token(ChainId.RINKEBY, '0x6A2eDf35279FB398Abc8BE5CC8eD007d64726fc7', 18, 'BTC', 'Bitcoin'),
  [ChainId.GÖRLI]: new Token(ChainId.RINKEBY, '0x6A2eDf35279FB398Abc8BE5CC8eD007d64726fc7', 18, 'BTC', 'Bitcoin'),
  [ChainId.KOVAN]: new Token(ChainId.RINKEBY, '0x6A2eDf35279FB398Abc8BE5CC8eD007d64726fc7', 18, 'BTC', 'Bitcoin'),
  80001: new Token(ChainId.RINKEBY, '0x6A2eDf35279FB398Abc8BE5CC8eD007d64726fc7', 18, 'BTC', 'Bitcoin')
}

export const TETH: { [chainId in ChainId]: Token } = {
  137: new Token(137, '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619', 18, 'WETH', 'Wraper Ethereum'),
  80001: new Token(80001, '0x90336b0C3f80F1eD297D5A9aBD896070fCdAE54b', 18, 'ETH', 'Ethereum'),
  [ChainId.KOVAN]: new Token(ChainId.RINKEBY, '0x2d2b7A2a205b130d6265BC9E4Fe9A966D74ceB7E', 18, 'ETH', 'Ethereum'),
  [ChainId.GÖRLI]: new Token(ChainId.RINKEBY, '0x2d2b7A2a205b130d6265BC9E4Fe9A966D74ceB7E', 18, 'ETH', 'Ethereum'),
  [ChainId.ROPSTEN]: new Token(ChainId.RINKEBY, '0x2d2b7A2a205b130d6265BC9E4Fe9A966D74ceB7E', 18, 'ETH', 'Ethereum'),
  [ChainId.RINKEBY]: new Token(ChainId.RINKEBY, '0xc778417E063141139Fce010982780140Aa0cD5Ab', 18, 'ETH', 'Ethereum'),
  [ChainId.MAINNET]: new Token(ChainId.RINKEBY, '0x2d2b7A2a205b130d6265BC9E4Fe9A966D74ceB7E', 18, 'ETH', 'Ethereum')
}
export const TUSDT: { [chainId in ChainId]: Token } = {
  137: new Token(137, '0xc2132D05D31c914a87C6611C10748AEb04B58e8F', 18, 'USDT', 'Tether USD'),
  80001: new Token(ChainId.RINKEBY, '0x4FA214C9e33D481996bEc77C443245fBAeE85670', 18, 'USDT', 'Tether USD'),
  [ChainId.KOVAN]: new Token(ChainId.RINKEBY, '0x4FA214C9e33D481996bEc77C443245fBAeE85670', 18, 'USDT', 'Tether USD'),
  [ChainId.GÖRLI]: new Token(ChainId.RINKEBY, '0x4FA214C9e33D481996bEc77C443245fBAeE85670', 18, 'USDT', 'Tether USD'),
  [ChainId.ROPSTEN]: new Token(ChainId.RINKEBY, '0x4FA214C9e33D481996bEc77C443245fBAeE85670', 18, 'USDT', 'Tether USD'),
  [ChainId.RINKEBY]: new Token(ChainId.RINKEBY, '0x4FA214C9e33D481996bEc77C443245fBAeE85670', 18, 'USDT', 'Tether USD'),
  [ChainId.MAINNET]: new Token(ChainId.RINKEBY, '0x4FA214C9e33D481996bEc77C443245fBAeE85670', 18, 'USDT', 'Tether USD')
}
// Block time here is slightly higher (~1s) than average in order to avoid ongoing proposals past the displayed time
export const AVERAGE_BLOCK_TIME_IN_SECS = 13
export const PROPOSAL_LENGTH_IN_BLOCKS = 40_320
export const PROPOSAL_LENGTH_IN_SECS = AVERAGE_BLOCK_TIME_IN_SECS * PROPOSAL_LENGTH_IN_BLOCKS

export const GOVERNANCE_ADDRESS = '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'

export const TIMELOCK_ADDRESS = '0x1a9C8182C09F50C8318d769245beA52c32BE35BC'
export const DEFAULT_API_URL = process.env.REACT_APP_API_URL

export const APR_URL = process.env.REACT_APP_API_URL

const UNI_ADDRESS = '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984'
export const UNI: { [chainId in ChainId]: Token } = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [ChainId.RINKEBY]: new Token(ChainId.RINKEBY, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [ChainId.ROPSTEN]: new Token(ChainId.ROPSTEN, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [ChainId.GÖRLI]: new Token(ChainId.GÖRLI, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  80001: new Token(80001, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  137: new Token(137, UNI_ADDRESS, 18, 'UNI', 'Uniswap')
  // 56: new Token(56, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  // 97: new Token(97, UNI_ADDRESS, 18, 'UNI', 'Uniswap')
}

const UNI_V2_ADDRESS = '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984'
export const UNIV2: { [chainId in ChainId]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x919b75ff6c4e4e89528641cf13e1d7f45de3ac03',
    18,
    'UNI-V2',
    'Uniswap V2'
  ),
  [ChainId.RINKEBY]: new Token(ChainId.RINKEBY, UNI_V2_ADDRESS, 18, 'UNI-V2', 'Uniswap V2'),
  [ChainId.ROPSTEN]: new Token(ChainId.ROPSTEN, UNI_V2_ADDRESS, 18, 'UNI-V2', 'Uniswap V2'),
  [ChainId.GÖRLI]: new Token(ChainId.GÖRLI, '0x4C8436A19c25822d16CFAe17264cf4A49AaAC92c', 18, 'UNI-V2', 'Uniswap V2'),
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, UNI_V2_ADDRESS, 18, 'UNI-V2', 'Uniswap V2'),
  80001: new Token(80001, UNI_V2_ADDRESS, 18, 'UNI-V2', 'Uniswap V2'),
  137: new Token(137, UNI_V2_ADDRESS, 18, 'UNI-V2', 'Uniswap V2')
}

export const COMMON_CONTRACT_NAMES: { [address: string]: string } = {
  [UNI_ADDRESS]: 'UNI',
  [GOVERNANCE_ADDRESS]: 'Governance',
  [TIMELOCK_ADDRESS]: 'Timelock'
}

// TODO: specify merkle distributor for mainnet
export const MERKLE_DISTRIBUTOR_ADDRESS: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e'
}

const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.ROPSTEN]: [WETH[ChainId.ROPSTEN]],
  [ChainId.RINKEBY]: [WETH[ChainId.RINKEBY]],
  [ChainId.GÖRLI]: [WETH[ChainId.GÖRLI]],
  [ChainId.KOVAN]: [WETH[ChainId.KOVAN]],
  80001: [WETH[80001]],
  137: [WETH[137]]
  // [ChainId.BSC]: [WETH[ChainId.BSC]],
  // [ChainId.BSCtestnet]: [WETH[ChainId.BSCtestnet]]
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT, WBTC]
}

export const APILIST: ApiURLList = {
  [ChainId.MAINNET]: DEFAULT_API_URL + '/eth',
  [ChainId.ROPSTEN]: DEFAULT_API_URL,
  [ChainId.RINKEBY]: DEFAULT_API_URL + '/rinkeby',
  [ChainId.GÖRLI]: DEFAULT_API_URL,
  [ChainId.KOVAN]: DEFAULT_API_URL,
  80001: DEFAULT_API_URL + '/mumbai',
  137: DEFAULT_API_URL + '/matic'
  // 56: DEFAULT_API_URL + '/bsc',
  // 97: DEFAULT_API_URL + '/bsctest'
}

export const APR_API_LIST: ApiURLList = {
  [ChainId.MAINNET]: APR_URL + '/rinkeby',
  [ChainId.ROPSTEN]: APR_URL,
  [ChainId.RINKEBY]: APR_URL + '/rinkeby',
  [ChainId.GÖRLI]: APR_URL,
  [ChainId.KOVAN]: APR_URL,
  80001: APR_URL,
  137: APR_URL
  // 56: APR_URL + '/bsc',
  // 97: APR_URL + '/bsctest'
}
export const ADDITIONAL_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {
    '0xA948E86885e12Fb09AfEF8C52142EBDbDf73cD18': [new Token(ChainId.MAINNET, UNI_ADDRESS, 18, 'UNI', 'Uniswap')],
    '0x561a4717537ff4AF5c687328c0f7E90a319705C0': [new Token(ChainId.MAINNET, UNI_ADDRESS, 18, 'UNI', 'Uniswap')],
    [FEI.address]: [TRIBE],
    [TRIBE.address]: [FEI],
    [FRAX.address]: [FXS],
    [FXS.address]: [FRAX],
    [WBTC.address]: [renBTC],
    [renBTC.address]: [WBTC]
  }
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {
    [AMPL.address]: [DAI, WETH[ChainId.MAINNET]]
  }
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT, WBTC]
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT, WBTC]
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [
      new Token(ChainId.MAINNET, '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643', 8, 'cDAI', 'Compound Dai'),
      new Token(ChainId.MAINNET, '0x39AA39c021dfbaE8faC545936693aC917d5E7563', 8, 'cUSDC', 'Compound USD Coin')
    ],
    [USDC, USDT],
    [DAI, USDT]
  ]
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  // INJECTED: {
  //   connector: injected,
  //   name: 'Injected',
  //   iconName: 'arrow-right.svg',
  //   description: 'Injected web3 provider.',
  //   href: null,
  //   color: '#010101',
  //   primary: true
  // },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  }
  // WALLET_CONNECT: {
  //   connector: walletconnect,
  //   name: 'WalletConnect',
  //   iconName: 'walletConnectIcon.svg',
  //   description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
  //   href: null,
  //   color: '#4196FC',
  //   mobile: true
  // },
  // WALLET_LINK: {
  //   connector: walletlink,
  //   name: 'Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Use Coinbase Wallet app on mobile device',
  //   href: null,
  //   color: '#315CF5'
  // },
  // COINBASE_LINK: {
  //   name: 'Open in Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Open in Coinbase Wallet app.',
  //   href: 'https://go.cb-w.com/mtUDhEZPy1',
  //   color: '#315CF5',
  //   mobile: true,
  //   mobileOnly: true
  // },
  // FORTMATIC: {
  //   connector: fortmatic,
  //   name: 'Fortmatic',
  //   iconName: 'fortmaticIcon.png',
  //   description: 'Login using Fortmatic hosted wallet',
  //   href: null,
  //   color: '#6748FF',
  //   mobile: true
  // },
  // Portis: {
  //   connector: portis,
  //   name: 'Portis',
  //   iconName: 'portisIcon.png',
  //   description: 'Login using Portis hosted wallet',
  //   href: null,
  //   color: '#4A6C9B',
  //   mobile: true
  // }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(60 * 60 * 24 * 7)

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000))

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')
export const FACTORY_ADDRESS = '0xC6e0Eeb5E45857BB2ca38ef8C139ED1bBeFFB837'

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = [
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b',
  '0x901bb9583b24D97e995513C6778dc6888AB6870e',
  '0xA7e5d5A720f06526557c513402f2e6B5fA20b008',
  '0x8576aCC5C05D6Ce88f4e49bf65BdF0C62F91353C'
]

export const ProtocolViewer_Address: { [chainId in ChainId]?: string } = {
  1: '0x6A9c7d126d17c35785d06D9AE17f6215ffE425e9',
  80001: '0x6A9c7d126d17c35785d06D9AE17f6215ffE425e9',
  [ChainId.RINKEBY]: '0x6531927903C73942af0484717503b763F0021eA3'
}

export const portfolioFactory: { [chainId in ChainId]?: string } = {
  1: '0x12BF894c47D9246e92F58d6AE22E1A32fd6D306E',
  137: '0x12BF894c47D9246e92F58d6AE22E1A32fd6D306E',
  80001: '0x2ECD6C9B4C2139bdE1FA53ba0c623E68F6823B36',
  [ChainId.RINKEBY]: '0xD22761d8362a0e28466CD5E526Be4Fa69EadfdCD'
}

export const portfolioModule: { [chainId in ChainId]?: string } = {
  1: '0x6ca55AB2627736A19D9d481171354e3630a442Cc',
  137: '0x6ca55AB2627736A19D9d481171354e3630a442Cc',
  80001: '0x6b453d2B6Bca48803a89Df064cdC2a7CAecD5539',
  [ChainId.RINKEBY]: '0x5aF22A32E90aa8195cf15a0675225e46CFb30EE5'
}

export const managerModule: { [chainId in ChainId]?: string } = {
  1: '0xcD206e0C843b72B39D20efE28B8190c4E142b862',
  137: '0xcD206e0C843b72B39D20efE28B8190c4E142b862',
  80001: '0xDc8d6AfC03BD7d4c28a3f825910685885c29de45',
  [ChainId.RINKEBY]: '0xaDc90097C29f749D91CFaD6614Eb43c69a113224'
}

export const trading: { [chainId in ChainId]?: string } = {
  1: '0x539db2B4FE8016DB2594d7CfbeAb4d2B730b723E',
  137: '0x539db2B4FE8016DB2594d7CfbeAb4d2B730b723E',
  80001: '0x3fE886Eab74C7F3b5c1f833Cf1b843F1c4c39f27',
  [ChainId.RINKEBY]: '0x53B6a4f9845D23493d4c14561F35d2af659BF26E'
}

// Epsilon to calculate the max stake value
export const epsilon = new Decimal(0.000000000000000001)
export const zeroDecimal = new Decimal(0)

export const NULL_TOKEN: { [chainId in ChainId]: Token } = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, ZERO_ADDRESS, 10, 'CERE'),
  [ChainId.ROPSTEN]: new Token(ChainId.ROPSTEN, ZERO_ADDRESS, 10, 'CERE'),
  [ChainId.RINKEBY]: new Token(ChainId.RINKEBY, ZERO_ADDRESS, 10, 'CERE'),
  [ChainId.GÖRLI]: new Token(ChainId.GÖRLI, ZERO_ADDRESS, 10, 'CERE'),
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, ZERO_ADDRESS, 10, 'CERE'),
  [ChainId.POLYGON]: new Token(ChainId.POLYGON, ZERO_ADDRESS, 10, 'CERE'),
  [ChainId.MUMBAI]: new Token(ChainId.MUMBAI, ZERO_ADDRESS, 10, 'CERE')
}

export const NULL_TOKEN_AMOUNT = {
  [ChainId.MAINNET]: new TokenAmount(NULL_TOKEN[ChainId.MAINNET], JSBI.BigInt(0)),
  [ChainId.ROPSTEN]: new TokenAmount(NULL_TOKEN[ChainId.ROPSTEN], JSBI.BigInt(0)),
  [ChainId.RINKEBY]: new TokenAmount(NULL_TOKEN[ChainId.RINKEBY], JSBI.BigInt(0)),
  [ChainId.GÖRLI]: new TokenAmount(NULL_TOKEN[ChainId.GÖRLI], JSBI.BigInt(0)),
  [ChainId.KOVAN]: new TokenAmount(NULL_TOKEN[ChainId.KOVAN], JSBI.BigInt(0)),
  [ChainId.POLYGON]: new TokenAmount(NULL_TOKEN[ChainId.POLYGON], JSBI.BigInt(0)),
  [ChainId.MUMBAI]: new TokenAmount(NULL_TOKEN[ChainId.MUMBAI], JSBI.BigInt(0))
}

export enum StakingMode {
  Default,
  SingleToken
}

const stakingModeParam = process.env.REACT_APP_STAKING_MODE
let stakingModeTmp: StakingMode
if (!stakingModeParam || stakingModeParam === 'DEFAULT') {
  stakingModeTmp = StakingMode.Default
} else if (stakingModeParam === 'SINGLE_TOKEN') {
  stakingModeTmp = StakingMode.SingleToken
} else {
  throw new Error(`Unknown StakingMode ${stakingModeParam}`)
}
export const stakingMode: StakingMode = stakingModeTmp

export const CERE_DECIMAL = 10

export const UNI_DECIMAL = 18

export enum PoolStatus {
  Open,
  AlmostFull,
  Full,
  NotCalculated
}

export enum PoolActiveStatus {
  Active,
  Inactive,
  Unknown
}
