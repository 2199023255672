import React, { Suspense, useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import AddressClaimModal from '../components/claim/AddressClaimModal'
import Header from '../components/Header'
import Polling from '../components/Header/Polling'
// import URLWarning from '../components/Header/URLWarning'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import { ApplicationModal } from '../state/application/actions'
import { useModalOpen, useToggleModal } from '../state/application/hooks'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import Stake from './Stake'
import { ReactComponent as TwitterLogo } from '../assets/svg/twitter.svg'
import { ReactComponent as MediumLogo } from '../assets/svg/medium.svg'
import { ReactComponent as TelegramLogo } from '../assets/svg/telegram.svg'
import { ReactComponent as GithubLogo } from '../assets/svg/github.svg'

import './App.less'
import { setupEthRinkebyTestNetwork } from 'utils'
import { useActiveWeb3React } from 'hooks'
import { ChainId } from '@uniswap/sdk'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
  min-height: 100vh;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;
  ${({ theme }) =>
    theme.mediaWidth.upToSmall`
    padding: 16px;
    padding-top: 2rem;
  `};

  z-index: 1;
`

const Marginer = styled.div`
  margin-top: 0;
`
const Footer = styled.div`
  width: 100%;
  height: 128px;
  padding: 56px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.bg6};
  svg {
    margin: 0 10px;
  }
`

const StyledLink = styled.a``
function TopLevelModals() {
  const open = useModalOpen(ApplicationModal.ADDRESS_CLAIM)
  const toggle = useToggleModal(ApplicationModal.ADDRESS_CLAIM)
  return <AddressClaimModal isOpen={open} onDismiss={toggle} />
}

export default function App() {
  const { pathname } = useLocation()
  const { chainId } = useActiveWeb3React()
  console.log('chainId', chainId)
  console.log('location: ', pathname)
  useEffect(() => {
    const ethereum = window.ethereum
    if (ethereum && ethereum.on) {
      ethereum.on('chainChanged', (_chainId: any) => window.location.reload())
      ethereum.on('disconnect', (_err: any) => window.location.reload())
      ethereum.on('accountsChanged', (_err: any) => window.location.reload())
    }
  }, [])

  useEffect(() => {
    const chainIdArray = [ChainId.MAINNET, ChainId.ROPSTEN, ChainId.RINKEBY, ChainId.GÖRLI]
    document.addEventListener('visibilitychange', function() {
      if (document.visibilityState === 'visible') {
        if (chainId && !(chainIdArray.indexOf(chainId) > -1)) {
          setupEthRinkebyTestNetwork(4)
        }
      }
    })
    if (document.visibilityState === 'visible') {
      if (chainId && !(chainIdArray.indexOf(chainId) > -1)) {
        setupEthRinkebyTestNetwork(4)
      }
    }
  }, [chainId])
  return (
    <Suspense fallback={null}>
      <Route component={GoogleAnalyticsReporter} />
      <Route component={DarkModeQueryParamReader} />
      <AppWrapper>
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
        <BodyWrapper>
          <Popups />
          <Polling />
          <TopLevelModals />
          <Web3ReactManager>
            <Switch>
              <Route exact strict path="/" component={Stake} />
            </Switch>
          </Web3ReactManager>
          <Marginer />
        </BodyWrapper>
      </AppWrapper>
    </Suspense>
  )
}
