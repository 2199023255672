import LanguageDetector from 'i18next-browser-languagedetector'
import i18n from 'i18next'
import enUsTrans from './locales/en_US.json'
import koKRTrans from './locales/ko_KR.json'

import { initReactI18next } from 'react-i18next'

i18n
  .use(LanguageDetector)
  .use(initReactI18next) //init i18next
  .init({
    resources: {
      en: {
        translation: enUsTrans
      },
      ko: {
        translation: koKRTrans
      }
    },
    //选择默认语言，选择内容为上述配置中的key，即en/ko
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })

export default i18n
