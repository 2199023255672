import { useCallback, useEffect, useState } from 'react'
import Decimal from 'decimal.js'
import { StakingMode, stakingMode } from '../../constants'

const gqlQuery = {
  query: `{
 pair(id: "0x919b75ff6c4e4e89528641cf13e1d7f45de3ac03"){
     totalSupply
     reserve0
     reserve1
     token0Price
     token1Price
     token0 {
       id
       symbol
       name
       decimals
     }
     token1 {
       id
       symbol
       name
       decimals
     }
 }
}`,
  variables: {}
}

const calculateAPRForDefaultMode = (
  totalStakedLP: Decimal,
  swapReserveInCERE: Decimal,
  swapTotalSupplyLP: Decimal,
  totalReward: Decimal,
  campaignDurationInDays: Decimal
): string =>
  totalReward
    .div(campaignDurationInDays)
    .div(totalStakedLP)
    .div(swapReserveInCERE.div(swapTotalSupplyLP).mul(2))
    .mul(366)
    .mul(100)
    .toNumber()
    .toFixed(2)

const calculateAPRForSingleTokenMode = (
  totalStaked: Decimal,
  totalReward: Decimal,
  campaignDurationInDays: Decimal
): string =>
  totalReward
    .div(campaignDurationInDays)
    .div(totalStaked)
    .mul(366)
    .mul(100)
    .toNumber()
    .toFixed(2)

const useCalculateAPR = (totalStake: string) => {
  const [apr, setApr] = useState<string>('')

  const calculateForDefaultMode = useCallback(
    async (swapReserveInCERE, swapTotalSupplyLP) => {
      try {
        const totalStakeLPVar = new Decimal(totalStake).mul(1e-18)
        const swapReserveInCEREVar = new Decimal(swapReserveInCERE)
        const swapTotalSupplyLPVar = new Decimal(swapTotalSupplyLP)
        const totalRewardVar = new Decimal(Number(process.env.REACT_APP_STAKING_TOTAL_REWARD))
        const campaignDurationInDaysVar = new Decimal(Number(process.env.REACT_APP_STAKING_CAMPAIGN_DURATION_IN_DAYS)) // J18:

        const finalAPR = calculateAPRForDefaultMode(
          totalStakeLPVar,
          swapReserveInCEREVar,
          swapTotalSupplyLPVar,
          totalRewardVar,
          campaignDurationInDaysVar
        )

        setApr(finalAPR)
      } catch (error) {
        console.error('calculate for default mode function ERROR:', error)
      }
    },
    [totalStake]
  )

  const calculateForSingleTokenMode = useCallback(() => {
    try {
      const totalStakeVar = new Decimal(totalStake).mul(1e-10)
      const totalRewardVar = new Decimal(Number(process.env.REACT_APP_STAKING_TOTAL_REWARD))
      const campaignDurationInDaysVar = new Decimal(Number(process.env.REACT_APP_STAKING_CAMPAIGN_DURATION_IN_DAYS)) // J18:

      const finalAPR = calculateAPRForSingleTokenMode(totalStakeVar, totalRewardVar, campaignDurationInDaysVar)

      setApr(finalAPR)
    } catch (error) {
      console.error('calculate for single token mode ERROR:', error)
    }
  }, [totalStake])

  useEffect(() => {
    async function fetchCurrencyPairUniswapData() {
      await fetch('https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(gqlQuery)
      })
        .then(res => res.json())
        .then(result => {
          calculateForDefaultMode(result.data.pair.reserve0, result.data.pair.totalSupply)
        })
        .catch(error => console.error('fetchCurrencyPairUniswapData function ERROR:', error))
    }

    if (stakingMode === StakingMode.Default) {
      fetchCurrencyPairUniswapData()
    } else {
      calculateForSingleTokenMode()
    }
  }, [calculateForDefaultMode])

  return apr
}

export { useCalculateAPR, calculateAPRForDefaultMode, calculateAPRForSingleTokenMode }
