import React from 'react'
import styled from 'styled-components'
import { useConvertToIntlCurrency, usePoolStatus, usePrice } from 'state/stake/hooks'

export default function DefaultTokenMode(props: { totalSupply: number; stakePoolCap: number; isHoverActive: boolean }) {
  const tsUsdValue = usePrice(props.totalSupply)
  const spUsdValue = usePrice(props.stakePoolCap)
  const totalStakedValue = useConvertToIntlCurrency(tsUsdValue)
  const stakedPoolCap = useConvertToIntlCurrency(spUsdValue)
  const { poolColor, poolHelpText } = usePoolStatus(tsUsdValue, spUsdValue)

  return totalStakedValue == 0 ? (
    <>...loading</>
  ) : (
    <div>
      <BlockContainer>
        <ColorCircle data-title={poolHelpText} color={poolColor} isHoverActive={true}></ColorCircle>
        <div>
          {totalStakedValue} / {stakedPoolCap}
        </div>
      </BlockContainer>
    </div>
  )
}

export const ColorCircle = styled.span<{ isHoverActive: boolean }>`
  height: 10px;
  width: 10px;
  background-color: ${props => props.color};
  border-radius: 50%;
  margin-right: 10px;
  ${({ isHoverActive }) =>
    isHoverActive &&
    `
  cursor: pointer;
  &:hover::after {
    content: attr(data-title);
    padding: 3px;
    position: absolute;
    top: 0px
    left: 40px;
    background: #bd32a6;
    color: #fbf9f9;
    border-radius: 5px;
  }
`}
`
export const BlockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
