import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const millisecondsInHour = 1000 * 60 * 60
const millisecondsInDay = 24 * millisecondsInHour

const showCountdownBefore = process.env.REACT_APP_SHOW_COUNTDOWN_BEFORE_IN_HOURS

const CounterStyled = styled.div`
  color: #af5af1;
  display: flex;
  flex-direction: column;
  font-weight: 100;
  text-align: center;
  padding: 10px 25px;
  span {
    font-weight: 500;
    font-size: 24px;
  }
  h2 {
    color: #af5af1;
    padding: 0;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
    margin: 0px 0px;
  }
`

const WrapperStyled = styled.div`
  display: flex;
  border-radius: 20px;
  align-items: center;
  text-align: center;
  justify-content: center;
  background: #1f2844;
  box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.5);
`

const Colon = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  font-size: 30px;
  color: #af5af1;
`

const CounterContainer = styled.section`
  padding: 0px;
  align-items: center;
  h4 {
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 4px;
    color: #f8f8fa;
    font-weight: 300;
  }
`

const ExpiredCounterContainer = styled.section`
  padding: 0px;
  align-items: center;
  display: flex;
  flex-direction: column;
  h4 {
    font-family: 'Inter';
    font-size: 24px;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 4px;
    color: red;
    font-weight: 300;
    margin: 0;
  }
  h5 {
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 4px;
    color: white;
    font-weight: 300;
    a {
      color: #af5af1;
      text-decoration: underline;
    }
  }
`

interface CounterType {
  displayValue: number
  label: string
}

// components
const Counter = ({ displayValue, label }: CounterType) => (
  <CounterStyled>
    <span>{displayValue}</span>
    <h2>{label}</h2>
  </CounterStyled>
)

export default function CountdownTimer({ finishPeriod }: { finishPeriod: any }) {
  const [timerDays, setTimerDays] = useState(0)
  const [timerHours, setTimerHours] = useState(0)
  const [distanceInHours, setDistanceInHours] = useState(0)

  const startTimer = (finishPeriod: number | undefined) => {
    if (finishPeriod !== undefined) {
      const now = new Date().getTime()
      const distance = finishPeriod - now

      if (distance < 0) {
        setTimerDays(0)
        setTimerHours(0)
      } else {
        const days = Math.floor(distance / millisecondsInDay)
        const hours = Math.floor((distance % millisecondsInDay) / millisecondsInHour)
        const distanceInHours = Math.floor(distance / millisecondsInHour)

        // Update Timer
        setTimerDays(days)
        setTimerHours(hours)
        setDistanceInHours(distanceInHours)
      }
    }
  }

  useEffect(() => {
    startTimer(finishPeriod)
  }, [finishPeriod])

  return finishPeriod === undefined || finishPeriod === 0 ? (
    <></>
  ) : timerDays === 0 && timerHours === 0 ? (
    <ExpiredCounterContainer>
      <h4>STAKING ENDED</h4>
      <h5>
        Stake on{' '}
        <a
          href="https://cere-network.medium.com/how-to-bridge-to-cere-mainnet-and-stake-e3ad3fe1a594"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cere Mainnet
        </a>
      </h5>
    </ExpiredCounterContainer>
  ) : Number(showCountdownBefore) > distanceInHours ? (
    <CounterContainer>
      <h4>STAKING ENDS IN</h4>
      <WrapperStyled>
        <Counter displayValue={timerDays} label={'Days'} />
        <Colon>:</Colon>
        <Counter displayValue={timerHours} label={'Hours'} />
      </WrapperStyled>
    </CounterContainer>
  ) : (
    <></>
  )
}
