import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { TokenAmount } from '@uniswap/sdk'
import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/es/table'
import {
  isNullStakingInfo,
  useConvertToIntlCurrency,
  useFetchStakePoolCap,
  useFetchStakingFinishPeriod,
  useFetchStakingPeriodStatus,
  useFetchTotalSupply,
  usePoolStatus,
  usePrice,
  useStakingInfo
} from '../../state/stake/hooks'
import CurrencyLogo from '../../components/CurrencyLogo'
import Modal from './Modal'
import { ButtonPrimary, ButtonLight } from '../../components/Button'
import banner from '../../assets/images/stake-banner.jpg'
import numeral from 'numeral'
import { log } from 'util'
import { useCalculateAPR } from './stake.service'
import Decimal from 'decimal.js'
import { CERE_DECIMAL, PoolActiveStatus, PoolStatus, stakingMode, StakingMode, UNI_DECIMAL } from '../../constants'
import DefaultTokenMode, { ColorCircle, BlockContainer } from 'components/Tvl/DefaultTokenMode'
import CountdownTimer from '../../components/Countdown'

export default function Stake() {
  const stakingInfos = useStakingInfo()
  const finishPeriod = useFetchStakingFinishPeriod()
  const [showStakingModal, setShowStakingModal] = useState(false)
  const [showUnStakingModal, setShowUnStakingModal] = useState(false)
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)
  const [stakingInfo, setStakingInfo] = useState<any>()
  const stakedUsdPrice = usePrice(Number(stakingInfos[0]?.stakedAmount.toFixed(10)))
  const balanceUsdPrice = usePrice(Number(stakingInfos[0]?.lpBalanceAmount.toFixed(10)))
  const { t } = useTranslation()
  const history = useHistory()
  const apr = useCalculateAPR(stakingInfos[0]?.totalStake[0].toString())
  const decimal = stakingMode === StakingMode.Default ? UNI_DECIMAL : CERE_DECIMAL
  const ts = Number(useFetchTotalSupply()) / 10 ** decimal
  const sp = Number(useFetchStakePoolCap()) / 10 ** decimal
  const totalStakedValue = useConvertToIntlCurrency(ts)
  const stakedPoolCap = useConvertToIntlCurrency(sp)
  const { poolColor, poolHelpText, poolStatus } = usePoolStatus(ts, sp)
  const stakingPoolActiveStatus = useFetchStakingPeriodStatus()

  const StakeColumn: ColumnsType<any> = [
    {
      title: t('pool'),
      dataIndex: 'lpBalanceAmount',
      key: 'tokens',
      align: 'center',
      render: (text: any) => {
        return (
          <StyleTokenName>
            {/* <CurrencyLogo currency={text[0]} size={'24px'} /> */}
            {/* <CurrencyLogo currency={text[1]} size={'24px'} /> */}
            {/* <DoubleCurrencyLogo currency0={text[0].symbol} currency1={text[1].symbol} /> */}
            {stakingMode === StakingMode.Default ? (
              <a href={process.env.REACT_APP_UNISWAP_CURRENCY_PAIR_URL} target="_blank" rel="noopener noreferrer">
                LP {stakingInfos[0]?.tokens[1]?.symbol}
              </a>
            ) : (
              <> {stakingInfos[0]?.tokens[1]?.symbol} </>
            )}
            &nbsp;
            {/* {`${text[0].symbol}-${text[1].symbol} LP`}  */}
          </StyleTokenName>
        )
      }
    },
    {
      title: t('APR'),
      dataIndex: 'APR',
      key: 'APR',
      align: 'center',
      render: () => {
        return !isFinite(Number(apr)) ? '...loading' : `${apr} %`
      }
    },
    {
      title: `${t('TVL')} (${stakingMode === StakingMode.Default ? 'USD' : 'CERE'})`,
      dataIndex: 'TVL',
      key: 'TVL',
      align: 'center',
      width: 200,
      ellipsis: true,
      render: () => {
        return stakingPoolActiveStatus === PoolActiveStatus.Active ? (
          totalStakedValue === 0 ? (
            '...loading'
          ) : stakingMode === StakingMode.Default ? (
            <DefaultTokenMode totalSupply={ts} stakePoolCap={sp} isHoverActive={true}></DefaultTokenMode>
          ) : (
            <div>
              <BlockContainer>
                <ColorCircle data-title={poolHelpText} color={poolColor} isHoverActive={true}></ColorCircle>
                <div>
                  {totalStakedValue} / {stakedPoolCap}
                </div>
              </BlockContainer>
            </div>
          )
        ) : stakingPoolActiveStatus === PoolActiveStatus.Inactive ? (
          <BlockContainer>
            <ColorCircle color={'red'} isHoverActive={false}></ColorCircle>
            <div>Expired</div>
          </BlockContainer>
        ) : (
          '...loading'
        )
      }
    },
    {
      title: `${t('staked')} (${stakingMode === StakingMode.Default ? 'UNI-V2' : 'CERE'})`,
      dataIndex: 'stakedAmount',
      key: 'stakedAmount',
      align: 'center',
      render: (stakedAmount: TokenAmount, record: any) => {
        return isNullStakingInfo(record) ? (
          <> - </>
        ) : (
          <>
            {parseFloat(stakedAmount?.toFixed(10))}
            <br />
            {stakedUsdPrice == 0 ? <> </> : <> (~ ${stakedUsdPrice.toFixed(2)})</>}
          </>
        )
      }
    },
    {
      title: t('rewards'),
      dataIndex: 'earnedAmount',
      key: 'earnedAmount',
      align: 'center',
      render: (earnedAmount: TokenAmount, record: any) => {
        return (
          <>
            {Number(earnedAmount?.toFixed(2)) == 0 ? <> - </> : Number(earnedAmount?.toFixed(2))}
            {Number(earnedAmount?.toFixed(2)) > 0 && (
              <ButtonLight
                onClick={() => {
                  setStakingInfo(record)
                  setShowClaimRewardModal(true)
                }}
              >
                {t('claim')}
              </ButtonLight>
            )}
          </>
        )
      }
    },
    {
      title: `${t('balance')} (${stakingMode === StakingMode.Default ? 'UNI-V2' : 'CERE'})`,
      dataIndex: 'lpBalanceAmount',
      key: 'lpBalanceAmount',
      align: 'center',
      render: (lpBalanceAmount: TokenAmount, record: any) => {
        return isNullStakingInfo(record) ? (
          <> - </>
        ) : (
          <>
            {parseFloat(lpBalanceAmount?.toFixed(10))}
            <br />
            {balanceUsdPrice == 0 ? <> </> : <> (~ ${balanceUsdPrice?.toFixed(2)})</>}
          </>
        )
      }
    },
    {
      title: t('operations'),
      dataIndex: 'operations',
      key: 'operations',
      align: 'center',
      render: (_: any, record: any) => {
        return isNullStakingInfo(record) ? (
          <> - </>
        ) : (
          <ButtonContainer>
            {record.stakedAmount?.toSignificant(4) > 0 && (
              <ButtonLight
                onClick={() => {
                  setStakingInfo(record)
                  setShowUnStakingModal(true)
                }}
              >
                {t('unstake')}
              </ButtonLight>
            )}
            {stakingPoolActiveStatus == PoolActiveStatus.Active && poolStatus !== PoolStatus.Full && (
              <ButtonPrimary
                onClick={() => {
                  setStakingInfo(record)
                  setShowStakingModal(true)
                }}
              >
                {t('stake')}
              </ButtonPrimary>
            )}
            {record.stakedAmount?.toSignificant(4) <= 0 &&
              (stakingPoolActiveStatus == PoolActiveStatus.Inactive || poolStatus == PoolStatus.Full) && <> - </>}
          </ButtonContainer>
        )
      }
    }
  ]

  return (
    <UserStake>
      <StakeWraper>
        <StakeBanner>
          <h1>
            {stakingMode === StakingMode.Default
              ? 'Stake UNI-V2 LP Tokens and earn CERE token rewards'
              : 'Stake CERE ERC-20 tokens to earn rewards in CERE'}
          </h1>
          <CountdownTimer finishPeriod={finishPeriod}></CountdownTimer>
        </StakeBanner>
        <StakeContent>
          <StakeTitle>Stake</StakeTitle>
          {stakingMode === StakingMode.Default && (
            <StakeDescription>
              Provide liquidity in the Uniswap V2{' '}
              <a href={process.env.REACT_APP_UNISWAP_CURRENCY_PAIR_URL} target="_blank" rel="noopener noreferrer">
                USDT/CERE
              </a>
              &nbsp;pool first. Then stake your LP Tokens here to earn CERE rewards.
            </StakeDescription>
          )}
          <Table
            scroll={{ x: true }}
            dataSource={stakingInfos}
            columns={StakeColumn}
            pagination={false}
            rowKey="stakingRewardAddress"
          />
        </StakeContent>
      </StakeWraper>
      <Modal
        showStakingModal={showStakingModal}
        setShowStakingModal={setShowStakingModal}
        showUnstakingModal={showUnStakingModal}
        setShowUnstakingModal={setShowUnStakingModal}
        showClaimRewardModal={showClaimRewardModal}
        setShowClaimRewardModal={setShowClaimRewardModal}
        stakingInfo={stakingInfo}
        apr={!isFinite(Number(apr)) ? '...loading' : `${apr} %`}
      />
    </UserStake>
  )
}

const UserStake = styled.div`
  width: 100%;
`

const StakeContent = styled.div`
  /* padding-top: 70px; */
  margin: 0 auto;
  max-width: 1080px;
  width: 100%;
`

const StakeTitle = styled.div`
  color: ${({ theme }) => theme.bg6};
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
`
const StakeDescription = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
`

const StakeBanner = styled.div`
  width: 100%;
  margin-bottom: 30px;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${banner}) 100%;
  height: 450px;
  flex-direction: column;
  h1 {
    font-size: 48px;
    color: #fff;
    margin-bottom: 0;
  }
`

const CommonWraper = styled.div`
  padding-bottom: 50px;
`
const StakeWraper = styled(CommonWraper)`
  width: 100%;
  .ant-table-thead > tr > th {
    background: #ffffff;
    color: ${({ theme }) => theme.bg6};
  }
`

const StyleTokenName = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  img,
  svg {
    margin-right: 8px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  button {
    &:first-of-type {
      margin-right: 10px;
    }
  }
`
