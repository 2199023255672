import { Currency, ETHER, Token } from '@uniswap/sdk'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import EthereumLogo from '../../assets/images/ethereum-logo.png'
import CereLogoSvg from '../../assets/images/cere.svg'
import UsdtLogoSvg from '../../assets/images/usdt.svg'
import MaticLogo from '../../assets/images/matic-token-icon.png'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'

export const getTokenLogoURL = (token: string | undefined) =>
  `${process.env.TOKEN_LOGO_URL}/sts/token/${token && token.toLowerCase()}.png`

const StyledEthereumLogo = styled.img<{ size: string; showBoxShadow?: boolean | undefined }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 24px;
  box-shadow: ${({ showBoxShadow }) => (showBoxShadow ? '0px 6px 10px rgba(0, 0, 0, 0.075)' : 'none')};
  background: #ddd;
  border-radius: 50%;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  background: #ddd;
  border-radius: 50%;
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style
}: {
  currency?: any
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) return []

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURL(currency.symbol)]
      }
      return [getTokenLogoURL(currency.symbol)]
    }
    return [getTokenLogoURL(currency.symbol)]
  }, [currency, uriLocations])

  if (currency === ETHER) {
    return <StyledEthereumLogo src={EthereumLogo} size={size} style={style} />
  }

  if (currency.symbol === 'UNI-V2') {
    return <StyledEthereumLogo src={UsdtLogoSvg} size={size} style={style} />
  }

  return <StyledEthereumLogo src={CereLogoSvg} size={size} style={style} />
}

export function ChainLogo({
  chainId,
  size = '24px',
  style,
  showBoxShadow = true
}: {
  chainId?: number
  size?: string
  style?: React.CSSProperties
  showBoxShadow?: boolean
}) {
  if (chainId === 137) {
    return <StyledEthereumLogo src={MaticLogo} size={size} style={style} showBoxShadow={showBoxShadow} />
  }
  return <StyledEthereumLogo src={EthereumLogo} size={size} style={style} showBoxShadow={showBoxShadow} />
}
